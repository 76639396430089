<template>
  <v-container fluid fill-height class="pa-0">
    <div id="mapContainer" class="mapcontainer"></div>

  </v-container>
</template>

<script>
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet.markercluster/dist/leaflet.markercluster";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";

export default {
  name: "ClientMap",
  data() {
    return {
      dialog: false,
      map_location: null,
      location: null,
      map: null,
    };
  },
  props: {
    mapData: {
      type: Array,
      required: true,
    },
  },
  methods: {
    getLocation() {
      return new Promise((resolve, reject) => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (res) => {
              this.location = [res.coords.latitude, res.coords.longitude];
              resolve(this.location);
            },
            (err) => {
              console.log(err);
              this.location = null;
              reject(err);
            }
          );
        } else {
          reject("No Geolocation support.");
        }
      });
    },
    initMap() {
      var layer;
      //var cartagena = this.location;
      //const HEREKEY = "_5kfPl6OjGTRMvgP5evdNY5nPBBwRVfQanf9zYDJorc";

      document.getElementById("mapContainer").innerHTML =
        "<div id='map' style='height: 100%'><v-btn>Menu</v-btn></div>";

      this.map = new L.Map("map");

      var redIcon = L.icon({
        iconUrl: "https://sw67.ca/pins/pin-red.png",
        iconSize: [30, 30],
      });

      const { map } = this;

      // var osmUrl =
      //   "https://tiles.stadiamaps.com/tiles/outdoors/{z}/{x}/{y}{r}.png",
      //   osmAttrib =
      //     '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors';


      // var osmUrl =
      //           "https://tiles.stadiamaps.com/tiles/outdoors/{z}/{x}/{y}{r}.png",
      //         osmAttrib =
      //           '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors';


      // var osmUrl =
      //   "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
      //   osmAttrib =
      //     'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community';



      var osmUrl = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        osmAttrib =
          '&copy; <a href="https://openstreetmap.org/copyright">OpenStreetMap</a> contributors';


      // var osmUrl =
      //     "https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png",
      //   osmAttrib =
      //     '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a> contributors';

      // var osmUrl =
      //     "https://tiles.stadiamaps.com/tiles/osm_bright/{z}/{x}/{y}{r}.png",
      //   osmAttrib =
      //     '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a> contributors';

      // var osmUrl = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      //   osmAttrib =
      //     '&copy; <a href="https://openstreetmap.org/copyright">OpenStreetMap</a> contributors';

      // var osmUrl =
      //     "https://2.base.maps.ls.hereapi.com/maptile/2.1/maptile/newest/normal.day/{z}/{x}/{y}/512/png8?apiKey=" +
      //     HEREKEY +
      //     "&ppi=320",
      //   osmAttrib = "&copy; HERE 2019";

      //   const provider = new HereProvider({
      //   params: {
      //     apiKey: HEREKEY,
      //   },
      // });

      L.tileLayer(osmUrl, {
        attribution: osmAttrib,
      }).addTo(map);

      if (layer != undefined) {
        map.removeLayer(layer);
      }

      var markers = [];
      var markersLayer = new L.LayerGroup();
      markersLayer.clearLayers();
      var locationCoor = [];
      var marker;
      var bounds = new L.LatLngBounds();

      for (var i = 0; i < this.mapData.length; i++) {
        if (this.mapData[i].location) {
          var dta = JSON.parse(this.mapData[i].location);
          
          if (dta.lat && dta.lng) {
            var lat = parseFloat(dta.lat);
            var lng = parseFloat(dta.lat);
            locationCoor[i] = [lat, lng];
            var store_location = [dta.lat, dta.lng];
            locationCoor.push(store_location);
            var name = this.mapData[i].name;
            var mobile = this.mapData[i].mobile;
            var popup = L.popup()
              .setLatLng(store_location)
              .setContent(
                "<div class='box'><b>" +
                name +
                "</b><p>" +
                this.mapData[i].address +
                "<br>" +
                mobile +
                "</p></a>  <a class='button' href='/" +
                this.mapData[i].code +
                "'>Menú</a><div>"
              );
            marker = L.marker(store_location, {
              icon: redIcon,
              clickable: true,
            }).bindPopup(popup, {
              showOnMouseOver: true,
            });
            markers.push(marker);
            bounds.extend(marker.getLatLng());
          }
        }
        markersLayer.addLayer(marker);
        if (bounds._northEast) {
          map.fitBounds([
            [bounds._southWest.lat, bounds._southWest.lng],
            [bounds._northEast.lat, bounds._northEast.lng],
          ]);
        } else {
          map.panTo(this.location);
        }
      }
      //console.log(locationCoor);
      markersLayer.addTo(map);
      markers = [];
    },
    recenter(e) {
      var dta = JSON.parse(e.geodata);
      this.map.setView(dta, 30);
      this.dialog = false;
    },
  },
  watch: {
    mapData: function (e) {
      console.log(e);
      this.initMap();
    },
  },
  mounted() {
    this.getLocation().then(() => {
      this.initMap();
    });
  },
};
</script>

<style lang="scss">
.container {
  padding: 0px !important;
}

#map {
  width: 100%;
  z-index: 0;
}

.v-btn--example {
  top: 10px;
  position: absolute;
  margin: 0 0 0 0;
}

.mapcontainer {
  height: 100vh;
  width: 100%;
}

.box {
  width: 350px;
}

.button {
  background: #075e54;
  color: white;
  padding: 5px;
  width: 100px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  margin: 10px;
  border-radius: 25px;
  cursor: pointer;
}

a {
  color: white;
}

a:link {
  color: white;
}

/* visited link */
a:visited {
  color: white;
}

/* mouse over link */
a:hover {
  color: white;
}

/* selected link */
a:active {
  color: white;
}
</style>
