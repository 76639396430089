<template>
  <v-container fluid>

    <ClientMap :mapData="clients" />
  </v-container>
</template>

<script>
import { webserver } from "../../services/webserver.js";
import ClientMap from "../../components/ClientMap.vue";
export default {
  components: { ClientMap },
  data() {
    return {
      errors: [],
      loading_status: false,
      clients: [],
    };
  },
  mounted() {
    this.get_clients();
  },
  methods: {
    get_clients() {
      this.loading_status = true;
      var qry = {
        account: this.$store.state.profile.account,
        table: "clients",
        filters: [
          {field: "location",operator: "<>", value: "",},
          {field: "account",operator: "=",value: this.$store.state.profile.account},
        ],
      };
      webserver("get_table", qry, (data) => {
        this.clients = data;
      });
    },
  },
};
</script>
